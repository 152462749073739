/* 
   font-family: "Lora", serif;
   @import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');
*/
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Lora:ital,wght@0,400..700;1,400..700&display=swap');


:root {
  --bs-primary: #FAF7EB;
  --bs-secondary: #151616;
}

.App {
   font-family: 'Fjalla One', sans-serif;
   font-weight: 400;
   font-style: normal;
 }

.Footer p{
   font-family: 'Fjalla One', sans-serif;
}
.lora {
   font-family: "Lora", serif;   
}
.fjalla {
   font-family: 'Fjalla One', sans-serif;
}
body {
   background-color: #151616;
   color: #FAF7EB; 
}
p {
   font-family: "Lora", serif;
   color: #FAF7EB;; 
}
a {
   color: #FAF7EB; 
}
h1 {
   font-family: 'Fjalla One', sans-serif;
   color: #FAF7EB; 
}
h2 {
  color: #FAF7EB; 
}
.page-header {
   font-family: 'lora', serif;
   color: #FAF7EB; 
   font-size: 32px;
}
header {
   color: #FAF7EB; 
}
li {
   color: #FAF7EB; 
}
.socialicons {
   width: 18px;
   margin-right: 2px;
}
.footerend{
   display: flex;
   justify-content: right;
}

.homecontainer {
   display: flex;
   flex-direction: column;
}
@media (min-width: 1080px) {
   .homecontainer {
       flex-direction: row;
   }
}

.homediv {
   position: relative;
   flex: 1;
   margin: 10px;
}

.overlay-text-icons {
   position: absolute;
   top: 45%;
   left: 40%;
   transform: translate(-50%, -50%);
   color: #FAF7EB;
   font-size: 48px;

}

.overlay-text {
   position: absolute;
   top: 33%;
   left: 35%;
   transform: translate(-20%, -20%);
   color: #FAF7EB;
   font-size: 72px;
   width: 50%;
   font-weight: bold;
}

.overlay-text a {
   color: #FAF7EB;
}

.overlay-text a:hover {
   color: #151616;  /* Slightly darker shade of the original color */
   transition: color 0.2s ease;
}

.overlay-text-secondary {
   font-size: 16px;
}

.overlay-pattern {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   font-family: monospace;
   opacity: 0.1;
   z-index: 1;
}

